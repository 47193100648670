import { Formik, FormikProps, FormikErrors, FormikTouched } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { getBreakpoint } from '../../../../base/style';
import Button from '../../../../components/Button';
import { Input } from '../../../../components/FormFields';
import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel';
import GenderSelect, { GenderFormOptions } from '../../../../components/GenderSelect';
import { FullPageLoader } from '../../../../components/Loading/index';
import PageTitle from '../../../../components/PageTitle';
import RepositCard from '../../../../components/RepositCard';
import { P1 } from '../../../../components/Typography';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import {
  updateAboutYouRequested,
  UPDATE_ABOUT_YOU_STORE_KEY,
} from '../../../../redux/order-customer-actions/order-customer-actions.actions';
import { getCurrentOrderCustomer } from '../../../../redux/order/order.selectors';
import { getCurrentUser } from '../../../../redux/selectors/user.selectors';
import { UPDATE_USER_STORE_KEY } from '../../../../redux/user/user.actions';
import { AboutYouErrorMessage } from './AboutYouErrorMessage';

interface AboutYouProps {}

const Action = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    justify-content: flex-end;
    flex-direction: row;
  }

  p {
    margin: 0 20px 0 0;
  }
`;

const Field = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 96%;
  }
`;

const FieldAlt = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    float: right;
    max-width: 96%;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-top: 0px;
  }
`;

const Schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('This must be a valid email address').required('Email address is required'),
  attributes: Yup.object().shape({
    dob: Yup.object()
      .shape({
        day: Yup.number()
          .transform((value, originalValue) => {
            return String(originalValue).includes('.') ? NaN : value;
          })
          .integer('Day must be a whole number')
          .typeError('Day must be a number')
          .required('Day is required')
          .min(1, 'Invalid day')
          .max(31, 'Invalid day')
          .test('valid-day-for-month', 'Invalid day for this month', function (value) {
            const { month, year } = this.parent;
            if (!value || !month || !year) return true;
            const daysInMonth = moment(`${year}-${month}`, 'YYYY-M').daysInMonth();
            return value <= daysInMonth;
          }),
        month: Yup.number()
          .transform((value, originalValue) => {
            return String(originalValue).includes('.') ? NaN : value;
          })
          .integer('Month must be a whole number')
          .typeError('Month must be a number')
          .required('Month is required')
          .min(1, 'Invalid month')
          .max(12, 'Invalid month'),
        year: Yup.number()
          .transform((value, originalValue) => {
            return String(originalValue).includes('.') ? NaN : value;
          })
          .integer('Year must be a whole number')
          .typeError('Year must be a number')
          .required('Year is required')
          .test('is-four-digits', 'Year must be 4 digits', (value) => value?.toString().length === 4),
      })
      .test('not-future-date', 'Date cannot be in the future', function (value) {
        if (!value?.day || !value?.month || !value?.year) return true;
        const date = moment(`${value.year}-${value.month}-${value.day}`, 'YYYY-M-D');
        return date.isValid() && !date.isAfter(moment());
      })
      .test('valid-date', 'You must be at least 18 years old', function (value) {
        if (!value?.day || !value?.month || !value?.year) return true;
        const date = moment(`${value.year}-${value.month}-${value.day}`, 'YYYY-M-D');
        return date.isValid() && date.isBefore(moment().subtract(18, 'years'));
      })
      // final catch all
      .test('is-valid-date', 'Invalid date', function (value) {
        if (!value?.day || !value?.month || !value?.year) return true;
        return moment(`${value.year}-${value.month}-${value.day}`, 'YYYY-M-D').isValid();
      }),
    gender: Yup.string().required('Gender is required'),
    mobileNumber: Yup.string()
      .min(11, 'Incorrectly formatted phone number')
      .max(15, 'Incorrectly formatted phone number')
      .matches(/^\+?[0-9]+$/, 'Incorrectly formatted phone number')
      .required('Required'),
  }),
});

interface UserAttributes {
  dob?: {
    day: string;
    month: string;
    year: string;
  };
  gender?: GenderFormOptions;
  mobileNumber?: string;
}

interface AboutYouFormValues {
  firstName: string;
  lastName: string;
  email: string;
  attributes: UserAttributes;
}

const getDOBErrors = (errors: FormikErrors<AboutYouFormValues>, touched: FormikTouched<AboutYouFormValues>) => {
  const dobError = get(errors, 'attributes.dob');
  const dobTouched = get(touched, 'attributes.dob');

  if (dobError) {
    // Handle the overall age validation error
    if (typeof dobError === 'string') {
      return [{ error: dobError, field: 'dob' }];
    }

    // Handle individual field errors
    if (typeof dobError === 'object' && dobTouched) {
      return Object.keys(dobError)
        .map((field) => {
          const error = dobError[field];
          const isTouched = dobTouched[field] || dobTouched === true;
          return error && isTouched ? { error, field } : undefined;
        })
        .filter((error) => Boolean(error)) as { error: string; field: string }[];
    }
  }

  return [];
};

const AboutYou: React.FC<AboutYouProps> = (props) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const { order, customer } = useSelector(getCurrentOrderCustomer);
  const attributes = get(currentUser, 'attributes', {});
  const dob = get(attributes, 'dob') as string | undefined;
  const isLoadingSelector = createLoadingSelector([UPDATE_USER_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);
  const isUpdateSubmittingSelector = createLoadingSelector([UPDATE_ABOUT_YOU_STORE_KEY]);
  const isUpdateSubmitting = useSelector(isUpdateSubmittingSelector);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Formik
      initialValues={{
        firstName: get(currentUser, 'firstName', ''),
        lastName: get(currentUser, 'lastName', ''),
        email: get(currentUser, 'email', ''),
        attributes: {
          dob: dob
            ? {
                day: moment(dob).format('DD'),
                month: moment(dob).format('MM'),
                year: moment(dob).format('YYYY'),
              }
            : undefined,
          gender: get(currentUser, 'attributes.gender', undefined),
          mobileNumber: get(currentUser, 'attributes.mobileNumber', undefined),
        },
      }}
      validationSchema={Schema}
      onSubmit={(data) => {
        const month = data.attributes?.dob?.month?.padStart(2, '0');
        const day = data.attributes?.dob?.day?.padStart(2, '0');
        dispatch(
          updateAboutYouRequested(customer.id, order.id, {
            firstName: data.firstName,
            lastName: data.lastName,
            dob: `${data.attributes?.dob?.year}-${month}-${day}`,
            gender: data.attributes.gender,
            mobileNumber: data.attributes.mobileNumber,
          })
        );
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }: FormikProps<AboutYouFormValues>) => {
        const dobErrors = getDOBErrors(errors, touched);
        return (
          <form onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <PageTitle tooltip="Before you can purchase your Reposit we need to collect a bit of information about you. This information is used to conduct checks to determine wether you are eligible to use Reposit.">
                    Tell us a bit about yourself
                  </PageTitle>
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <RepositCard title="What's your name?">
                    <Container fluid>
                      <Row>
                        <Col lg={6} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel label="First name" touched={touched.firstName} error={errors.firstName}>
                              <Input
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="firstName"
                                touched={touched.firstName}
                                error={errors.firstName}
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                        <Col lg={6} style={{ padding: 0 }}>
                          <FieldAlt>
                            <FieldWithLabel label="Last name" touched={touched.lastName} error={errors.lastName}>
                              <Input
                                value={values.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="lastName"
                                touched={touched.lastName}
                                error={errors.lastName}
                              />
                            </FieldWithLabel>
                          </FieldAlt>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} style={{ padding: 0 }}>
                          <Field>
                            <FieldWithLabel
                              label="Don’t worry, we’ve already got your email"
                              touched={touched.email}
                              error={errors.email}
                            >
                              <Input
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="email"
                                touched={touched.email}
                                error={errors.email}
                                disabled
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                        <Col lg={6} style={{ padding: 0 }}>
                          <FieldAlt>
                            <FieldWithLabel
                              label="Mobile number"
                              touched={touched.attributes && touched.attributes.mobileNumber}
                              error={errors.attributes && errors.attributes.mobileNumber}
                            >
                              <Input
                                value={values.attributes.mobileNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="attributes.mobileNumber"
                                touched={touched.attributes && touched.attributes.mobileNumber}
                                error={errors.attributes && errors.attributes.mobileNumber}
                                placeholder="e.g. 07000 000 000"
                              />
                            </FieldWithLabel>
                          </FieldAlt>
                        </Col>
                      </Row>
                    </Container>
                  </RepositCard>
                </Col>
              </Row>
              <Row>
                <Col lg={5} push={{ lg: 1 }} style={{ display: 'flex' }}>
                  <RepositCard title="What’s your date of birth?">
                    <>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                        <div style={{ width: '25%' }}>
                          <FieldWithLabel label="Day">
                            <Input
                              value={values.attributes.dob?.day}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="attributes.dob.day"
                              error={get(errors, 'attributes.dob.day', undefined)}
                              touched={get(touched, 'attributes.dob.day', false)}
                            />
                          </FieldWithLabel>
                        </div>
                        <div style={{ width: '25%' }}>
                          <FieldWithLabel label="Month">
                            <Input
                              value={values.attributes.dob?.month}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="attributes.dob.month"
                              error={get(errors, 'attributes.dob.month', undefined)}
                              touched={get(touched, 'attributes.dob.month', false)}
                            />
                          </FieldWithLabel>
                        </div>
                        <div style={{ width: '50%' }}>
                          <FieldWithLabel label="Year">
                            <Input
                              value={values.attributes.dob?.year}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="attributes.dob.year"
                              error={get(errors, 'attributes.dob.year', undefined)}
                              touched={get(touched, 'attributes.dob.year', false)}
                            />
                          </FieldWithLabel>
                        </div>
                      </div>
                      {dobErrors.length ? (
                        <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
                          {dobErrors.map(({ error, field }) => (
                            <AboutYouErrorMessage error={error} key={field} />
                          ))}
                        </div>
                      ) : null}
                    </>
                  </RepositCard>
                </Col>
                <Col lg={5} push={{ lg: 1 }} style={{ display: 'flex' }}>
                  <GenderSelect
                    selected={values.attributes.gender}
                    touched={touched.attributes && touched.attributes.gender}
                    error={errors.attributes && errors.attributes.gender}
                    onSelect={(gender) => {
                      setFieldValue('attributes.gender', gender);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={10} push={{ lg: 1 }}>
                  <Action>
                    <P1>Next: Confirm your details</P1>
                    <ButtonWrapper>
                      <Button buttonType="primary" type="submit" disabled={isUpdateSubmitting}>
                        Continue
                      </Button>
                    </ButtonWrapper>
                  </Action>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default AboutYou;
